"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedPaymasters = exports.initCtf = exports.getNetworks = exports.switchNetwork = exports.Ctf = void 0;
var networks_1 = require("../config/networks");
var common_1 = require("@opengsn/common");
var provider_1 = require("@opengsn/provider");
var paymasters_1 = require("@opengsn/paymasters");
var ethers_1 = require("ethers");
var CtfArtifact = __importStar(require("../artifacts/contracts/CaptureTheFlag.sol/CaptureTheFlag.json"));
/**
 * a wrapper class for the CTF contract.
 * the only network-specific "leak" from this class is that the "capture()"
 * event returns a transaction object,
 * that the application should wait() until it gets mined.
 */
var Ctf = /** @class */ (function () {
    function Ctf(address, signer, gsnProvider, chainId, paymasterDetails) {
        this.address = address;
        this.gsnProvider = gsnProvider;
        this.chainId = chainId;
        this.paymasterDetails = paymasterDetails;
        this.blockDates = {};
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.ethersProvider = signer.provider;
        this.gsnProvider = gsnProvider;
        this.theContract = new ethers_1.ethers.Contract(address, CtfArtifact.abi, signer);
        this.blockDates = {};
    }
    Ctf.prototype.getCurrentFlagHolder = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.theContract.currentHolder()];
            });
        });
    };
    Ctf.prototype.listenToEvents = function (onEvent, onProgress) {
        var _this = this;
        // @ts-expect-error
        var listener = function (from, to, event) { return __awaiter(_this, void 0, void 0, function () {
            var info;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getEventInfo(event)];
                    case 1:
                        info = _a.sent();
                        onEvent(info);
                        return [2 /*return*/];
                }
            });
        }); };
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.theContract.on('FlagCaptured', listener);
        if (onProgress != null) {
            this.gsnProvider.registerEventListener(onProgress);
        }
    };
    Ctf.prototype.stopListenToEvents = function (onEvent, onProgress) {
        if (onProgress === void 0) { onProgress = null; }
        this.theContract.off(onEvent, null);
        this.gsnProvider.unregisterEventListener(onProgress);
    };
    Ctf.prototype.getBlockDate = function (blockNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(this.blockDates[blockNumber] == null)) return [3 /*break*/, 2];
                        _a = this.blockDates;
                        _b = blockNumber;
                        _c = Date.bind;
                        return [4 /*yield*/, this.ethersProvider.getBlock(blockNumber).then(function (b) {
                                return b.timestamp * 1000;
                            })];
                    case 1:
                        _a[_b] = new (_c.apply(Date, [void 0, _d.sent()]))();
                        _d.label = 2;
                    case 2: return [2 /*return*/, this.blockDates[blockNumber]];
                }
            });
        });
    };
    Ctf.prototype.getEventInfo = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (e.args == null) {
                            return [2 /*return*/, {
                                    previousHolder: 'notevent',
                                    currentHolder: JSON.stringify(e)
                                }];
                        }
                        _a = {};
                        return [4 /*yield*/, this.getBlockDate(e.blockNumber)];
                    case 1: return [2 /*return*/, (_a.date = _b.sent(),
                            _a.previousHolder = e.args.previousHolder,
                            _a.currentHolder = e.args.currentHolder,
                            _a)];
                }
            });
        });
    };
    Ctf.prototype.getPastEvents = function (count) {
        var _a, _b;
        if (count === void 0) { count = 5; }
        return __awaiter(this, void 0, void 0, function () {
            var currentBlock, lookupWindow, startBlock, logs;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.ethersProvider.getBlockNumber()];
                    case 1:
                        currentBlock = (_c.sent()) - 1;
                        lookupWindow = (_b = (_a = global.network) === null || _a === void 0 ? void 0 : _a.relayLookupWindowBlocks) !== null && _b !== void 0 ? _b : 30 * 24 * 3600 / 12;
                        startBlock = Math.max(1, currentBlock - lookupWindow);
                        return [4 /*yield*/, this.theContract.queryFilter(this.theContract.filters.FlagCaptured(), startBlock)
                                .catch(function (e) { return []; })];
                    case 2:
                        logs = _c.sent();
                        return [4 /*yield*/, Promise.all(logs.slice(-count).map(function (e) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.getEventInfo(e)];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); }))];
                    case 3: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    Ctf.prototype.getSigner = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.theContract.signer.getAddress()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Ctf.prototype.capture = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gasFees, gasLimit, ret;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        this.ethersProvider.getGasPrice().then(function (price) { return console.log('== gas price=', price.toString()); });
                        return [4 /*yield*/, this.gsnProvider.calculateGasFees()];
                    case 1:
                        gasFees = _a.sent();
                        gasFees.maxPriorityFeePerGas = gasFees.maxFeePerGas;
                        console.log('gas fees=', gasFees);
                        gasLimit = 1e5;
                        return [4 /*yield*/, this.theContract.captureTheFlag(__assign({ gasLimit: gasLimit }, gasFees))];
                    case 2:
                        ret = _a.sent();
                        console.log('post-capture ret=', ret);
                        return [2 /*return*/, ret];
                }
            });
        });
    };
    Ctf.prototype.getGsnStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var relayClient, ci;
            var _this = this;
            return __generator(this, function (_a) {
                relayClient = this.gsnProvider.relayClient;
                ci = relayClient.dependencies.contractInteractor;
                return [2 /*return*/, {
                        relayHubAddress: ci.relayHubInstance.address,
                        forwarderAddress: ci.forwarderInstance.address,
                        paymasterAddress: ci.paymasterInstance.address,
                        paymasterVersion: ci.paymasterVersion,
                        getPaymasterBalance: function () { return ci.relayHubInstance.balanceOf(ci.paymasterInstance.address); },
                        getActiveRelayers: function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, relayClient.dependencies.knownRelaysManager.refresh().then(function () {
                                            // count non-private relays
                                            return relayClient.dependencies.knownRelaysManager.allRelayers.filter(function (r) { return (0, provider_1.validateRelayUrl)(r.relayUrl); }).length;
                                        })];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); }
                    }];
            });
        });
    };
    Ctf.prototype.getPaymasterVersion = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var relayClient, ci, pm, v;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        relayClient = this.gsnProvider.relayClient;
                        ci = relayClient.dependencies.contractInteractor;
                        return [4 /*yield*/, ci._createPaymaster(address)];
                    case 1:
                        pm = _a.sent();
                        return [4 /*yield*/, pm.versionPaymaster()];
                    case 2:
                        v = _a.sent();
                        console.log('getPaymasterVersion', v);
                        return [2 /*return*/, v];
                }
            });
        });
    };
    return Ctf;
}());
exports.Ctf = Ctf;
function switchNetwork(id) {
    return __awaiter(this, void 0, void 0, function () {
        var hexChain, provider;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    hexChain = '0x' + parseInt(id).toString(16);
                    console.log('change network to ', hexChain);
                    provider = window.ethereum;
                    return [4 /*yield*/, provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: hexChain }]
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.switchNetwork = switchNetwork;
function getNetworks() {
    return Object.keys(networks_1.networks)
        .map(function (key) { return parseInt(key); })
        .filter(function (key) { return window.location.href.match(/local/) != null || (key !== 1337 && key !== 31337); })
        .reduce(function (set, key) {
        var _a;
        return (__assign(__assign({}, set), (_a = {}, _a[key] = networks_1.networks[key].name, _a)));
    }, {});
}
exports.getNetworks = getNetworks;
function initCtf(paymasterDetails) {
    return __awaiter(this, void 0, void 0, function () {
        var web3Provider, provider, network, chainId, net, netid, gsnConfig, gsnProvider, _a, provider2, signer;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    web3Provider = window.ethereum;
                    if (web3Provider == null) {
                        throw new Error('No "window.ethereum" found. do you have Metamask installed?');
                    }
                    web3Provider.on('chainChanged', function (chainId) {
                        console.log('chainChanged', chainId);
                        window.location.reload();
                    });
                    web3Provider.on('accountsChanged', function (accs) {
                        console.log('accountChanged', accs);
                        window.location.reload();
                    });
                    provider = new ethers_1.ethers.providers.Web3Provider(web3Provider);
                    return [4 /*yield*/, provider.getNetwork()];
                case 1:
                    network = _b.sent();
                    chainId = network.chainId;
                    net = global.network = networks_1.networks[chainId];
                    return [4 /*yield*/, provider.send('net_version', [])];
                case 2:
                    netid = _b.sent();
                    console.log('chainid=', chainId, 'networkid=', netid);
                    if (chainId !== parseInt(netid)) {
                        console.warn("Incompatible network-id ".concat(netid, " and ").concat(chainId, ": for Metamask to work, they should be the same"));
                    }
                    if (net == null || net.paymasters.length === 0) {
                        if (chainId.toString().match(/1337/) != null) {
                            throw new Error('To run locally, you must run "yarn evm" and then "yarn deploy" before "yarn react-start" ');
                        }
                        else {
                            throw new Error("Unsupported network (chainId=".concat(chainId, ") . please switch to one of: ") + Object.values(networks_1.networks).map(function (n) { return n.name; }).filter(function (n) { return n; }).join(' / '));
                        }
                    }
                    gsnConfig = {
                        loggerConfiguration: { logLevel: 'debug' },
                        paymasterAddress: paymasterDetails.debugUseType ? paymasterDetails.paymasterType : paymasterDetails.address
                    };
                    if (chainId === 42161) { // changes for arbitrum
                        gsnConfig.maxViewableGasLimit = 1e7.toString();
                        gsnConfig.environment = provider_1.environments.arbitrum;
                    }
                    if (chainId === 43114) { // changes for avalanche
                        gsnConfig.performDryRunViewRelayCall = false;
                    }
                    console.log('== gsnconfig=', JSON.stringify(gsnConfig));
                    _a = paymasterDetails.paymasterType;
                    switch (_a) {
                        case common_1.PaymasterType.AcceptEverythingPaymaster: return [3 /*break*/, 3];
                        case common_1.PaymasterType.PermitERC20UniswapV3Paymaster: return [3 /*break*/, 5];
                        case common_1.PaymasterType.SingletonWhitelistPaymaster: return [3 /*break*/, 6];
                    }
                    return [3 /*break*/, 8];
                case 3: return [4 /*yield*/, provider_1.RelayProvider.newWeb3Provider({ provider: web3Provider, config: gsnConfig })];
                case 4:
                    gsnProvider = _b.sent();
                    console.log('created new RelayProvider with config:', gsnConfig);
                    return [3 /*break*/, 9];
                case 5:
                    gsnProvider = paymasters_1.TokenPaymasterProvider.newProvider({ provider: web3Provider, config: gsnConfig });
                    console.log('created new TokenPaymasterProvider with config:', gsnConfig);
                    return [3 /*break*/, 9];
                case 6:
                    gsnConfig.dappOwner = paymasterDetails.dappOwner;
                    return [4 /*yield*/, provider_1.RelayProvider.newWeb3Provider({ provider: web3Provider, config: gsnConfig })];
                case 7:
                    gsnProvider = _b.sent();
                    console.log('created new RelayProvider with config:', gsnConfig);
                    return [3 /*break*/, 9];
                case 8: throw new Error("Paymaster of type ".concat(common_1.PaymasterType[paymasterDetails.paymasterType].toString(), "(").concat(paymasterDetails.paymasterType.toString(), ") is not currently supported!"));
                case 9:
                    provider2 = new ethers_1.ethers.providers.Web3Provider(gsnProvider);
                    signer = provider2.getSigner();
                    return [2 /*return*/, new Ctf(net.ctf, signer, gsnProvider, chainId, paymasterDetails)];
            }
        });
    });
}
exports.initCtf = initCtf;
function getSupportedPaymasters() {
    return __awaiter(this, void 0, void 0, function () {
        var web3Provider, provider, network, chainId, net;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    web3Provider = window.ethereum;
                    provider = new ethers_1.ethers.providers.Web3Provider(web3Provider);
                    return [4 /*yield*/, provider.getNetwork()];
                case 1:
                    network = _a.sent();
                    chainId = network.chainId;
                    console.log('getSupportedPaymasters', networks_1.networks, chainId);
                    net = networks_1.networks[chainId];
                    return [2 /*return*/, net.paymasters.map(function (paymasterDetails) {
                            var _a, _b, _c;
                            var paymasterAddress = (_a = paymasterDetails.address) !== null && _a !== void 0 ? _a : (0, common_1.getPaymasterAddressByTypeAndChain)(paymasterDetails.paymasterType, chainId, console);
                            if (paymasterAddress == null) {
                                throw new Error("CTF: Paymaster of type ".concat(common_1.PaymasterType[paymasterDetails.paymasterType].toString(), "(").concat(paymasterDetails.paymasterType.toString(), ") not found for chain ").concat(chainId));
                            }
                            var paymasterName = (_c = (_b = paymasterDetails.name) !== null && _b !== void 0 ? _b : common_1.PaymasterType[paymasterDetails.paymasterType]) !== null && _c !== void 0 ? _c : 'unknown_pm_name';
                            return {
                                name: paymasterName,
                                address: paymasterAddress,
                                paymasterType: paymasterDetails.paymasterType,
                                dappOwner: paymasterDetails.dappOwner,
                                /** For debugging only - if set will not pass address to the GSN provider constructor */
                                debugUseType: paymasterDetails.address == null
                            };
                        })];
            }
        });
    });
}
exports.getSupportedPaymasters = getSupportedPaymasters;
